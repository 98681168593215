import { Config } from './types';

export const baseApiUri = process.env.REACT_APP_API_URL || 'https://inwf4fbyvm.eu-west-1.awsapprunner.com/api/v1';

export const config: Config = {
    name: process.env.REACT_APP_COMPANY_NAME || 'Ramp',
    logoUrl: process.env.REACT_APP_COMPANY_LOGO || 'https://imagedelivery.net/YV7tiPr1pNu8eWcEhMhZuA/b488a1ae-9726-4538-98bc-af7ba7ff7800/public',
    primaryColor: process.env.REACT_APP_PRIMARY_COLOR || '#e4f222',
    secondaryColor: process.env.REACT_APP_SECONDARY_COLOR || '#000',
    thirdColor: process.env.REACT_APP_THIRD_COLOR || '#000',
};
